.reserves-action-buttons-container {
  padding: 24px 0px;
}

.actions-col {
  padding: 0px;
}

.search-bar-col {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reserves-action-button {
  margin-right: 22px;
}

.reserves-list-container {
  padding: 0px;
}

.reserves-list {
  padding: 0px;
}

.reserves-item-container {
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reserve-item-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  padding: 40px;
  height: 340px;
  width: 340px;
  border-radius: 170px;
  overflow-wrap: anywhere;
}

.reserve-item-info:hover {
  cursor: pointer;
  background-color: white;
  color: black;
}

.reserve-item-circle {
  cursor: pointer;
}

.show-more-reserves-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.show-more-reserves-container button {
  width: 50%;
  margin: 75px 0px;
  padding: 10px;
}

.create-reserve-header p {
  font-size: 24px;
  font-weight: 500;
}

.create-reserve-form-input-container {
  padding: 18px 0px;
}

.create-reserve-form-input-container label {
  font-size: 1.1em;
  width: 100%;
}

.create-reserve-form-input-container input {
  margin-top: 10px;
  width: 25%;
}

@media screen and (max-width: 1000px) {
  .show-more-reserves-container button {
    margin: 0px 0px 40px 0px;
  }
}

.common-button {
  background-color: black;
  border: 1px solid white;
  padding: 11px;
  text-align: center;
  color: white;
}

.common-button:hover {
  background-color: white;
  border: 1px solid black;
  color: black;
}

html,
body,
#root,
.main,
.row,
.col-12,
.col-7,
.col-6,
.col-5,
.col-3,
.col-2,
h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: black;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-content-container {
  padding: 0px 40px;
}

.staking-container {
  padding: 0px 40px;
}

.staking-header-title {
  padding: 0px;
}

.staking-header-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.staking-claim-button {
  margin-left: 20px;
  padding: 10px 31px;
}

.staking-info-label {
  padding: 15px 0px;
  font-size: 18px;
}

.staking-form-input {
  padding: 0px 5px;
  width: 100px;
}

.staking-form-actions-container button:first-child {
  margin-right: 20px;
}

.pagenotfound-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.pagenotfound-container p {
  font-size: 44px;
  color: white;
  font-weight: bold;
  text-align: center;
}

.pagenotfound-container a {
  color: white;
}

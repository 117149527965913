.lock-title-container {
  padding: 0px;
  margin: 0px;
}

.lock-header-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.lock-claim-button {
  margin-left: 20px;
  padding: 10px 31px;
}

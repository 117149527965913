.selected-reserve-header {
}

.selected-reserve-label-container {
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px;
}

.selected-reserve-header-actions-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* background-color: blue; */
}

.selected-reserve-header-actions-container p {
  padding-right: 20px;
}

.selected-reserve-header-button {
  margin-left: 20px;
}

.selected-reserve-info-list-container p {
  padding: 15px 0px;
  font-size: 18px;
}

.selected-reserve-info-list-container p > a {
  color: white;
}

.reserve-deposit-modal-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  opacity: 0.9;
}

.deposit-form-label-container label {
  font-weight: 700;
}

.deposit-form-label-container label > a {
  color: white;
}

.reserve-deposit-form-input {
  padding-bottom: 2px;
  margin: 20px 0px;
}

.selected-reserve-form-button {
  margin-left: 22px;
}

.deposit-form-lend-button {
  margin: 10px 0px;
}

.active-lend-button {
  background-color: white;
  color: black;
}

.rewards-title-container {
  padding: 0px;
  margin: 0px;
}

.rewards-search-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rewards-search-button {
  padding: 10px 20px;
  margin: 0px 20px;
}

.rewards-earned-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rewards-info-container {
  padding: 0px;
  margin: 0px;
}

.rewards-info-label-container {
  padding: 15px 0px;
  font-size: 18px;
}

.rewards-claim-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rewards-claim-button {
  margin: 0px;
  padding: 10px 31px;
}

.main-container,
.app-desktop-container {
  margin: 0px;
  padding: 0px;
}

.app-mobile {
  display: none;
}

header {
  padding: 36px 40px;
}

.brand-name-container {
  padding: 0px;
}

.brand-name-container a {
  color: white;
  text-decoration: none;
  font-size: 28px;
  font-weight: bold;
}

.header-nav-container {
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header-nav-container a {
  color: white;
  text-decoration: none;
  font-weight: 400;
}

.nav-padded-link {
  margin-left: 80px;
}

.header-nav-container .active-link {
  font-weight: bold;
  border-bottom: 1px solid white;
  padding-bottom: 2px;
}

.header-nav-container a:hover {
  border-bottom: 1px solid white;
  padding-bottom: 2px;
}

.account-info-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 1204px) {
  .app-desktop-container {
    display: none;
  }
  .app-mobile {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
}

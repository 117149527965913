.common-input {
  background-color: black;
  border: none;
  border-bottom: 1px solid white;
  padding-bottom: 2px;
  color: white;
}

.common-input:focus {
  outline: none;
  border-bottom: 1px solid red;
}
